body
  margin: 0
  padding: 0

textarea
  font-size: 1em
  resize: none

input
  font-size: 1em

/* Activate if necesary */
input, select, textarea, button
  -webkit-appearance: none
  -moz-appearance: none
  -ms-appearance: none
  appearance: none

.wrapper
  @extend %clearfix
  @extend %bbx
  position: relative
  width: 90%
  max-width: 80rem
  margin-right: auto
  margin-left: auto
  +responsive(480)
    width: 100%

.air
  @extend %bbx
  padding: 1rem

  &_horz
    padding: 0 1rem

  &_vert
    padding: 1rem 0

.flex
  display: flex
  flex-direction: column
  min-height: 100vh
  min-height: 100dvh

// Styled-chekbox: https://codepen.io/Vestride/pen/dABHx
