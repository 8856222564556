.hero
  position: relative

  &_services
    height: 12rem
    background-image: url('../assets/img/sections/familia-mirando-tv2.jpg')
    background-size: cover
    background-position: center
    color: #fff
    +responsive(768)
      display: flex
      align-items: flex-end
      height: 100vh
      height: 100dvh

      &::after
        content: ''
        display: block
        position: absolute
        top: 0
        right: 0
        bottom: 0
        left: 0
        background-color: transparentize(#000, .5)
        z-index: 0

  &__title
    position: relative
    z-index: 1
    +responsive(769, min)
      display: none

  &__misc
    display: block
    fill: #000
    width: 100%
    min-width: 1000px

  .swiper
    width: 100%
    height: 100vh
    height: 100dvh

    &-slide
      display: flex
      justify-content: center
      align-items: center
      font-size: 18px
      color: #fff
      +responsive(768)
        align-items: flex-end

      &::after
        content: ''
        display: block
        position: absolute
        top: 0
        right: 0
        bottom: 0
        left: 0
        background-color: transparentize(#000, .5)
        z-index: -1

      h2
        text-transform: uppercase

      a
        color: inherit
        &:hover
          text-decoration: none

    &-button-next,
    &-button-prev
      color: #fff

  &__cover
    position: absolute
    display: block
    width: 100%
    height: 100%
    object-fit: cover
    z-index: -1
    
  &__miniature
    +responsive(769, min)
      position: absolute
      right: 0
      bottom: 2rem
      left: 0
      z-index: 1
    +responsive(768)
      padding: 1rem 0 
      background-color: $br-primary

    .wrapper
      padding: 1.5rem
      box-sizing: border-box
      border-radius: 1rem
      background-color: $br-primary
      color: #fff
      +responsive(768)
        padding: 1rem
        border-radius: 0

      .row
        display: flex
        gap: 1.5rem
        +responsive(768)
          flex-direction: column

        .col
          flex: 1 1 0px

