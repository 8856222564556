.footer
  padding: 1.5rem 0
  background-color: #000
  font-size: 1.25rem
  color: #fff

  .wrapper
    display: flex
    align-items: center
    justify-content: space-between
    +responsive(768)
      flex-direction: column
      text-align: center

    span
      color: $ne-70

  &__social
    display: flex
    gap: 1.5rem
    margin-left: auto
    +responsive(768)
      margin-left: 0
      margin-top: 1rem

    &Link
      font-size: 2rem
      color: #fff
      &:hover
        text-decoration: none
        color: $br-secondary