.post
  display: flex
  gap: 1rem
  &:hover
    text-decoration: none

  &__img
    width: 8rem
    height: 8rem
    border-radius: 1rem
    overflow: hidden
    +responsive(480)
      width: 6rem
      height: 6rem

    img
      width: 100%
      height: 100%
      object-fit: cover

  &__info
    color: #fff

    h4
      margin-top: 0
      margin-bottom: 0.5em
      text-transform: uppercase