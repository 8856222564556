.navigation
  position: fixed
  z-index: 100
  left: 0
  display: block
  width: 100%
  background-color: transparentize(#000, .1)
  +responsive(480)
    padding: 0.5rem 0

  &__logo
    display: block
    float: left
    width: 16rem
    +responsive(480)
      width: 12.5rem

    img
      display: block
      width: 100%

  &__trigger
    position: relative
    width: 32px
    height: 32px
    display: block
    float: right
    margin-top: 5px
    margin-right: 10px
    margin-left: 15px
    cursor: pointer
    +responsive(839, min)
      display: none
    +responsive(500)
      margin-right: 0
      margin-left: 0

  &__bars
    position: absolute
    z-index: 1011
    right: 0
    top: 0
    width: 32px
    height: 32px
    opacity: 0.8
    cursor: pointer
    &::before
      transform: rotate(90deg) translateX(5px)
    &::after
      transform: rotate(-90deg) translateX(5px)
    &:hover
      &::before
        transform: rotate(90deg) translateX(8px)
      &::after
        transform: rotate(-90deg) translateX(8px)

    &.is-opened
      &::before
        transform: rotate(50deg)
      &::after
        transform: rotate(-50deg)
      &:hover
        &::before
          transform: rotate(60deg)
        &::after
          transform: rotate(-60deg)

    &::before,
    &::after
      position: absolute
      left: 15px
      content: ''
      height: 33px
      width: 3px
      background-color: #000
      transition: transform 0.2s ease 0s

  &__menu
    float: right
    +responsive(840)
      width: 100%
      flex-wrap: wrap
      justify-content: space-between
      border-top: 1px solid transparentize(#000, 0.8)
      padding-top: 20px
      display: none

  &__item
    float: left
    margin-right: 4px
    margin-left: 4px

    +responsive(500)
      padding: 10px

    a
      padding: 5px
      text-decoration: none
      transition: all 0.2s ease 0s
      color: #000
