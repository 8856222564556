html, .root
  font-family: $ft-primary
  font-size: 16px
  line-height: 1.5

h1, .h1
  font-size: 5.625rem
  line-height: 1.3
  margin-top: .5em
  margin-bottom: .5em
  +responsive(480)
    font-size: 3.1875rem

h2, .h2
  font-size: 3.1875rem
  line-height: 1.3
  margin-top: .5em
  margin-bottom: .5em
  +responsive(480)
    font-size: 2.125rem

h3, .h3
  font-size: 1.75rem
  margin-top: 1em
  margin-bottom: 1em
  +responsive(480)
    font-size: 1.3125rem

h4, .h4
  font-size: 1.3125rem
  margin-top: 1em
  margin-bottom: 1em
  +responsive(480)
    font-size: 1.125rem

h5, .h5
  font-size: 1rem
  margin-top: 1.25em
  margin-bottom: 1.25em
